import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/components/Button';

import { Wrapper, Site, Sketch, BeeSketch } from './index.style'
import { Link } from 'gatsby';

const NotFound = ({ button }) => {

  return (
    <Wrapper>
      <Site>
        <Sketch>
          <BeeSketch className="red" />
          <BeeSketch className="blue" />
        </Sketch>
        <h1>
          Ups, la página no existe. 
          <small></small>
          <Link to="/">
            <Button {...button} title="INICIO" />
          </Link>
        </h1>
      </Site>
    </Wrapper>
  )
}

NotFound.propTypes = {
  button: PropTypes.object,
};

NotFound.defaultProps = {
  button: {
    mt: '25px',
    type: 'button',
    fontWeight: 'bolder',
    color: 'white',
    borderRadius: '4px',
    colors: 'primary',
    minWidth: '156px',
    fontSize: '18px',
  }
};

export default NotFound;
