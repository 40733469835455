import React from "react"
import SEO from "../components/seo"
import NotFound from "../containers/NotFound"

const NotFoundPage = () => (
  <>
    <SEO /> 
    <NotFound />
  </>
)

export default NotFoundPage
