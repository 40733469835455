import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 75vh;  
  min-height: 75vh;
  display: flex;
  margin-top: 80px;

  h1 {
    font-size: 50px;
    text-align: left;
    padding-right: 10px;
    opacity: .8;
    order: 2;
    margin 0;
  }

  h1 small {
    padding-top: 5px;
    display: block;
    font-size: 25px;
    color: #5d646d;
  }

  @media only screen and (max-width: 990px) {
    h1 {
      font-size: 40px;
      margin-left: 20px;
    }
  
    h1 small {
      display: block;
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 779px) {
    height: 100vh;  
    min-height: 100vh;
    h1 {
      font-size: 35px;
      margin-left: 40px;
    }
  
    h1 small {
      display: block;
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 780px) {
    h1 {
      text-align: left;
      order: 1;
    }
  }
`;

export const Site = styled.div`
  display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
  align-items: center;
	flex-direction: column;
	margin: 0 auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
  @media only screen and (min-width: 780px) {
    flex-direction: row;
  }
`;

export const Sketch = styled.div`
	position: relative;
	height: 400px;
	min-width: 400px;

	overflow: visible;
	order: 2;	
  @media only screen and (min-width: 780px) {
    order: 1;
  }
`;

export const BeeSketch = styled.div`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
`;
